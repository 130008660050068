import React from 'react';
import { useIntl } from 'react-intl';

const MainButton = ({ text, href }) => {
    const intl = useIntl();

    return (
        <button className='main-button'>
            <a href={href} target='_blank' rel='noreferrer' className='font-regular'>
                {intl.formatMessage({ id: text })}
            </a>
        </button>
    )
}
export default MainButton;