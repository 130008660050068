import './App.scss';
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import Home from './components/home/Home';

import pl from '../src/translations/pl';
import en from './translations/en';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';

const translations = {
  'pl': pl,
  'en': en
}

const App = () => {

  const [translation] = useState(translations.pl);

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <IntlProvider locale='pl' messages={translation}>
      <div className='app'>
        <Navbar />
        <Router>
          <Switch>
            <Route path='/'>
              <Home />
            </Route>
          </Switch>
        </Router>
        <Footer />
      </div>
    </IntlProvider>
  );
}

export default App;