import React from 'react';

const Message = ({ small }) => {
    return (
        <svg width={small ? "30" : "54"} height={small ? "30" : "54"} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54Z" fill="#1D7DEE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.946 39.6487V16.7568C10.946 15.5627 11.9099 14.5946 13.0989 14.5946H42.8242V16.9029H13.2445V39.6487H10.946ZM17.7854 20.7679L26.0674 29.3064C26.6305 29.9121 27.5738 29.951 28.1846 29.394C28.1846 29.394 35.3943 22.9583 37.4013 21.23C39.4081 19.5018 42.8241 19.9858 42.8241 22.56V34.7876C42.8241 37.4724 40.657 39.6487 37.9836 39.6487H16.9306V37.3405H37.9428C39.3691 37.3405 40.5256 36.179 40.5256 34.7465V23.0688C40.5256 22.2037 39.9277 22.2935 39.5516 22.6322L28.8369 31.9192C27.773 32.8776 26.1411 32.8039 25.1672 31.7535L16.1058 22.3436L17.7854 20.7679Z" fill="white" />
        </svg>
    )
}

export default Message;