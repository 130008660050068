// ż

const pl = {
    get_rid_of_debt: 'Pozbądź się zadłużenia',
    documents: 'Dokumenty',
    purchase_of_debt_portfolios: 'Zakup portfeli wierzytelności',
    contact: 'Kontakt',
    letter: 'Otrzymałaś/-łeś list od Debt Recovery? Co dalej?',
    letter_text: 'To bardzo ważne, żebyś poznał(-a) informacje, jakie zawiera list do Ciebie. Znajdują się w nim istotne informacje o Twoim zadłużeniu takie jak wysokość długu, dane pierwszego wierzyciela oraz dlaczego to Debt Recovery napisał do Ciebie ten list. Jeśli jednak nie wszystko jest dla Ciebie jasne to nasi Doradcy chętnie odpowiedzą na wszelkie pytania.',
    call_us: 'Zadzwoń do nas:',
    call_us_text: 'Zadzwoń do nas. Odpowiemy na wszelkie pytania dotyczące zadłużenia, doradzimy i pomożemy w pozbyciu się długu. Wspólnie ustalimy dogodne spłaty zadłużenia w ratach, dopasowanych do Twoich możliwości finansowych i domowego budżetu. Dla nas każdy dłużnik jest naszym Klientem. Każdego traktujemy z szacunkiem i ze zrozumieniem. Pracujemy po to, aby Ci pomóc.',
    text_us: 'Napisz do nas:',
    text_us_text: 'Napisz do nas e-mail. Odpowiemy na wszelkie pytania dotyczące zadłużenia, doradzimy i pomożemy w pozbyciu się długu. Wspólnie ustalimy dogodne spłaty zadłużenia w ratach, dopasowanych do Twoich możliwości finansowych i domowego budżetu. Dla nas każdy dłużnik jest naszym Klientem. Każdego traktujemy z szacunkiem i ze zrozumieniem. Pracujemy po to, aby Ci pomóc.',
    how_to_read: 'Objaśnienie jak czytać pismo, które otrzymałeś',
    payment: '* Opłata za połączenie zgodna z taryfą operatora.',
    available_hours: 'Nasi Doradcy są do Państwa dyspozycji od poniedziałku do piątku w godzinach ',
    send_us_an_email: 'Wyślij do nas e-mail:',
    email_text: 'Wysłanie prośby o kontakt jest równoznaczne z wyrażeniem zgody na przetwarzanie danych osobowych podanych w formularzu kontaktowym, celem uzyskania kontaktu zwrotnego. Podanie adresu mailowego jest równoznaczne z wyrażeniem zgody na otrzymywanie od Debt Recovery wiadomości, informacji i powiadomień za pośrednictwem udostępnionego adresu poczty elektronicznej, w tym także w formie załącznika do poczty elektronicznej.',
    leave_number: 'Zostaw numer telefonu - oddzwonimy do Ciebie',
    our_email: 'windykacja@debt-recovery.pl',
    debt_recovery: 'Debt Recovery',
    address: 'Adres',
    company_info: 'Dane firmy',
    menu: 'Menu',
    privacy_policy: 'Polityka prywatności',
    open: 'Czynne',
    type_date: 'Data wpisania do rejestru przedsiębiorców',
    privacy_policy_fb: 'Polityka prywatności FB',
    rodo: 'RODO',
    permissions: 'Zgody',
    home: 'Start',
    debt_text: 'Zarządza pakietami (portfelami) wierzytelności masowych (detalicznych) zakupionymi na własny rachunek.debt recovery pakiety wierzytelnosci. Nabywamy portfele wierzytelności zarówno w drodze przetargów jak także negocjacji dwustronnych.',
    contact_us: 'Zachęcamy do kontaktu',
    regulations: 'Regulamin'
}

export default pl;