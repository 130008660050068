import React from 'react';
import { useIntl } from 'react-intl';

import logo from '../../assets/images/logo.svg';

const Footer = () => {
    const intl = useIntl();

    return (
        <footer className='d-flex flex-row flex-wrap justify-content-evenly'>
            <div className='round' />
            <div className='col-12 text-center'>
                <img src={logo} alt='logo' data-aos='flip-up' data-aos-duration='1000' />
            </div>
            <div className='col-12 col-sm-4 col-md-2 d-flex flex-row flex-wrap'>
                <h5 className='font-medium'>{intl.formatMessage({ id: 'address' })}</h5>
                <p className='font-regular col-12'>Debt Recovery Sp. z o.o.</p>
                <p className='font-regular col-12'>ul. Grzybowska 80/82, lok 112</p>
                <p className='font-regular col-12'>00-844 Warszawa</p>
                <p className='font-regular col-12'>{intl.formatMessage({ id: 'open' })}: Pon- Pt 8:00 - 16:00</p>
            </div>
            <div className='col-12 col-sm-4 col-md-2 d-flex flex-row flex-wrap'>
                <h5 className='font-medium'>{intl.formatMessage({ id: 'contact' })}</h5>
                <a href='tel:+48223784451' className='font-bold col-12'>+48 223 784 451 *</a>
                <a href='mailto:biuro@debt-recovery.pl' target='_blank' rel='noreferrer' className='font-bold col-12'>biuro@debt-recovery.pl </a>
            </div>
            <div className='col-12 col-sm-4  col-md-2 d-flex flex-row flex-wrap'>
                <h5 className='font-medium'>{intl.formatMessage({ id: 'company_info' })}</h5>
                <p className='font-regular col-12'><span className='font-bold'>{intl.formatMessage({ id: 'type_date' })}</span>: <br /> 18 kwietnia 2017 r.</p>
                <p className='font-regular col-12'><span className='font-bold'>KRS:</span> 0000673964</p>
                <p className='font-regular col-12'><span className='font-bold'>NIP:</span> 5272805358</p>
                <p className='font-regular col-12'><span className='font-bold'>REGON:</span> 367088200</p>
            </div>
            <div className='col-12 col-sm-4 col-md-2 d-flex flex-row flex-wrap'>
                <h5 className='font-medium'>{intl.formatMessage({ id: 'menu' })}</h5>
                <a href='#nav' className='font-regular col-12'>{intl.formatMessage({ id: 'home' })}</a>
                <a href='#pozbadz-sie-zadluzenia' className='font-regular col-12'>{intl.formatMessage({ id: 'get_rid_of_debt' })}</a>
                {/* <a className='font-regular col-12'>{intl.formatMessage({ id: 'documents' })}</a> */}
                <a href='#zakup-portfeli-wierzytelnosci' className='font-regular col-12'>{intl.formatMessage({ id: 'purchase_of_debt_portfolios' })}</a>
                {/* <a className='font-regular col-12'>{intl.formatMessage({ id: 'contact' })}</a> */}
            </div>
            <div className='col-12 col-sm-4  col-md-2 d-flex flex-row flex-wrap'>
                <h5 className='font-medium'>{intl.formatMessage({ id: 'documents' })}</h5>
                <br/>
                <a className='font-regular col-12' href="http://debt-recovery.pl/site/klauzula_RODO_dot_czynnosci_windykacyjnych.pdf" target="_blank">Klauzula RODO dot. czynności windykacyjnych</a>
                <br/>
                <a className='font-regular col-12' href="http://debt-recovery.pl/site/polityka_prywatnosci_strony_www.pdf" target="_blank">Polityka prywatności strony internetowej</a>
                <br/>
                <a className='font-regular col-12' href="http://debt-recovery.pl/site/regulamin_strony_internetowej.pdf" target="_blank">Regulamin strony internetowej</a>
            </div>
        </footer>
    )
}

export default Footer;