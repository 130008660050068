import React from 'react';
import { useIntl } from 'react-intl';

const NavButton = ({ data }) => {
    const intl = useIntl();

    return (
        <a href={`#${data.id}`} className='nav-button font-medium'>
            { intl.formatMessage({ id: data.name })}
        </a>
    )
}

export default NavButton