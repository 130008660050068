import React from 'react';

import logo from '../../assets/images/logo.svg';

import NavButton from './partials/NavButton';

const Navbar = () => {

    const menu = [
        {
            name: 'get_rid_of_debt',
            id: 'pozbadz-sie-zadluzenia'
        },
        // {
        //     name: 'documents',
        //     id: 'documents'
        // },
        {
            name: 'purchase_of_debt_portfolios',
            id: 'zakup-portfeli-wierzytelnosci'
        },
        // {
        //     name: 'contact',
        //     id: 'contact'
        // }
    ]

    return (
        <nav className='d-flex flex-row flex-wrap justify-content-between' id='nav'>
            <img src={logo} alt='logo' data-aos='fade-down' data-aos-duration='1000'  />
            <div className='menu d-flex flex-row flex-wrap align-items-center'>
                {menu.map((item, index) => (
                    <NavButton
                        key={index}
                        data={item}
                    />
                ))}
            </div>
        </nav>
    )
}

export default Navbar;