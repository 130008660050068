import React from 'react';

const Phone = () => {
    return (
        <svg width="54" height="54" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42 79C62.4345 79 79 62.4345 79 42C79 21.5655 62.4345 5 42 5C21.5655 5 5 21.5655 5 42C5 62.4345 21.5655 79 42 79Z" fill="#1D7DEE" />
            <path d="M83.5 42C83.5 64.9198 64.9198 83.5 42 83.5C19.0802 83.5 0.5 64.9198 0.5 42C0.5 19.0802 19.0802 0.5 42 0.5C64.9198 0.5 83.5 19.0802 83.5 42Z" stroke="#1D7DEE" strokeOpacity="0.3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M47.7684 51.2211C47.1524 51.6123 46.3812 51.6686 45.7149 51.371C44.394 50.6384 41.9853 48.8901 38.6442 45.549C35.3031 42.2079 33.3995 39.6383 32.6613 38.334C32.3637 37.6677 32.42 36.8966 32.8112 36.2805L34.7315 32.9172C35.4197 31.724 35.5751 30.9692 34.8258 29.8092L29.8919 22.2557C28.4877 20.0357 27.7718 20.1356 26.5619 20.6906C20.2682 23.5544 17.8095 27.6059 26.7395 41.7084C29.1482 45.5102 36.5075 53.5577 42.7845 57.6314C56.2266 66.3615 60.65 63.7364 63.2751 57.6314C63.8301 56.3993 64.0743 55.6833 61.9598 54.3014L54.323 49.4451C53.163 48.7014 52.5969 48.5072 51.3981 49.2009L47.7684 51.2211Z" fill="white" />
        </svg>
    )
}

export default Phone;