import React from 'react';
import { useIntl } from 'react-intl';

import bg from '../../assets/images/bg.png';
import blur from '../../assets/images/blur.png';
import money from '../../assets/images/money.png';
import contact from '../../assets/images/contact.png';
import woman from '../../assets/images/woman.png';

import MainButton from '../_partials/buttons/MainButton';
import IconTitle from './partials/IconTitle';

import icons from '../../assets/icons/_icons';

const Home = () => {
    const intl = useIntl();

    return (
        <div className='home' id='home'>
            <section className='top-section'>
                <img className='bg' src={bg} alt='background' data-aos='fade-up' data-aos-duration='1000' />
                <img className='blur' src={blur} alt='blur' />
                <div className='inner-container d-flex flex-row align-content-center flex-wrap'>
                    <h1 className='font-bold'>Debt Recovery</h1>
                    <p className='font-regular'>{intl.formatMessage({ id: 'debt_text' })}</p>
                </div>
            </section>
            <section className='debt-section d-flex flex-row align-content-center flex-wrap' id='pozbadz-sie-zadluzenia'>
                <div className='d-flex flex-row col-12 col-md-4 align-items-center'>
                    <img src={money} className='col-12 col-md-4' alt='money' data-aos='fade-right' data-aos-duration='1000' data-aos-delay='200' />
                    <h2 className='font-bold top-title'>{intl.formatMessage({ id: 'get_rid_of_debt' })}</h2>
                </div>
                <div className='text-container col-12 col-md-8'>
                    <h2 className='font-bold'>{intl.formatMessage({ id: 'get_rid_of_debt' })}</h2>
                    <h3 className='font-medium'>{intl.formatMessage({ id: 'letter' })}</h3>
                    <p className='text-top'>{intl.formatMessage({ id: 'letter_text' })}</p>
                    <MainButton
                        text='how_to_read'
                        href='https://www.debt-recovery.pl/images/dokumenty/list_do_dluznika_objasnienie.pdf'
                    />
                    <h3 className='font-medium'>{intl.formatMessage({ id: 'call_us' })}</h3>
                    <p className='font-regular'>{intl.formatMessage({ id: 'call_us_text' })}</p>
                    <h3 className='font-medium'>{intl.formatMessage({ id: 'text_us' })}</h3>
                    <p className='font-regular'>{intl.formatMessage({ id: 'text_us_text' })}</p>
                </div>
            </section>
            <section className='contact-section w-100 d-flex flex-row flex-wrap'>
                <div className='col-12 col-md-6'>
                    <IconTitle
                        text='call_us'
                        icon='phone'
                    />
                    <div className='inner-container w-100'>
                        <div className='numbers-container col-12 col-sm-10 col-md-8 col-lg-6 flex-wrap d-flex flex-row align-items-center justify-content-evenly'>
                            <a href='tel:+48223784451' className='font-bold'>+48 223 784 451 *</a>
                        </div>
                        <div className='inner-text-container'>
                                <p className='font-regular'>{intl.formatMessage({ id: 'payment' })}</p>
                                <p className='font-regular'>{intl.formatMessage({ id: 'available_hours' })}<span className='font-bold'>8.00 - 16.00</span></p>
                                <img src={contact} className='col-12 col-md-6 contact-top' alt='contact' data-aos='fade-left' data-aos-duration='1000' data-aos-delay='200' />
                        </div>
                        {/* <MainButton
                            text='leave_number'
                        /> */}
                    </div>
                    <IconTitle
                        text='send_us_an_email'
                        icon='message'
                    />
                    <div className='inner-container'>
                        <MainButton
                            text='our_email'
                            href={'mailto:biuro@debt-recovery.pl'}
                        />
                        <p className='font-regular contact-text'>{intl.formatMessage({ id: 'email_text' })}</p>
                    </div>
                </div>
                <img src={contact} className='col-12 col-md-6 contact' alt='contact' data-aos='fade-left' data-aos-duration='1000' data-aos-delay='200' />
            </section>
            <section className='global-contact d-flex flex-row flex-wrap' id='zakup-portfeli-wierzytelnosci'>
                <img src={woman} className='col-12 col-md-5 contact' alt='contact woman' data-aos='fade-right' data-aos-duration='1000' data-aos-delay='200' />
                <div className='text-container col-12 col-md-7 d-flex flex-row align-content-center flex-wrap'>
                    <h2 className='font-bold col-12'>{intl.formatMessage({ id: 'debt_recovery' })}</h2>
                    {/* <p className='font-regular'>{intl.formatMessage({ id: 'debt_text' })}</p> */}
                    <h4 className='font-bold'>{intl.formatMessage({ id: 'contact_us' })}</h4>
                    <p className='font-bold col-12 address'>Debt Recovery Sp. z o.o. <br /> ul. Grzybowska 80/82, lok 112 <br /> 00-844 Warszawa</p>
                    <div className='flex-row d-flex align-items-center contact-inner'>
                        {icons.smallMessage}
                        <a href='mailto:biuro@debt-recovery.pl' target='_blank' rel='noreferrer' className='font-medium'>biuro@debt-recovery.pl</a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home;