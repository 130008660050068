import React from 'react';
import { useIntl } from 'react-intl';

import icons from '../../../assets/icons/_icons';

const IconTitle = ({ icon, text }) => {
    const intl = useIntl();

    return (
        <div className='icon-title d-flex flex-row align-items-center'>
            {icons[icon]}
            <h2 className='font-bold'>{intl.formatMessage({id: text})}</h2>
        </div>
    )
}

export default IconTitle;